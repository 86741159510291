































































import { defineComponent, ref, computed, watch, toRefs, PropType } from "@vue/composition-api";
import {
  AccountsClient,
  CreateAccountModel,
  IdentityResult,
  OrganizationMemberRegistrationModel
} from "@/api/OtiumAppApi";
import validatePassword from "@/utility/password_validator";

export default defineComponent({
  name: "CreateAccountForm",
  props: {
    invitation: {
      type: Object as PropType<OrganizationMemberRegistrationModel | null>,
      default: null
    },
    isStripeInvite: {
      type: Boolean,
      default: false
    },
    readOnlyEmail: {
      type: String,
      required: false
    },
    token: {
      type: String,
      required: false
    }
  },
  setup(props, { root, refs }) {
    const { invitation, isStripeInvite, token, readOnlyEmail } = toRefs(props);
    const displayCreateAccountDialog = ref(false);
    const email = ref("");
    const password = ref("");
    const firstName = ref("");
    const lastName = ref("");
    const organization = ref("");
    const creatingAccount = ref(false);
    const isFormValid = ref(false);
    const errorMessage = ref("");
    const showPassword = ref(false);
    const emailRules = ref([(v: string) => !!v || "Username is required"]);
    const passwordRules = ref([validatePassword]);

    function loadInvitation() {
      if (readOnlyEmail?.value != null) {
        email.value = readOnlyEmail.value;
      }
      if (invitation.value != null) {
        email.value = invitation.value.email;
        firstName.value = invitation.value.firstName;
        lastName.value = invitation.value.lastName;
      }
    }
    loadInvitation();
    watch(invitation, loadInvitation);
    const isOrganizationInvitation = computed(() => invitation.value != null);
    const isEmailDisabled = computed(
      () => isOrganizationInvitation.value || readOnlyEmail?.value != null
    );

    async function registrationClicked() {
      (refs.registrationForm as any).validate();
      if (!isFormValid.value) return;

      creatingAccount.value = true;
      const client = new AccountsClient();

      let model = {
        email: email.value,
        password: password.value,
        firstName: firstName.value,
        lastName: lastName.value,
        organizationName: organization.value
      } as CreateAccountModel;

      let result: IdentityResult;
      try {
        if (isStripeInvite.value) {
          // Confirm email from stripe invite
          result = await client.confirmEmail(model, token?.value);
        } else {
          // accept invite
          if (invitation.value == null) return;
          result = await client.acceptInvitation(invitation.value.organizationMemberId, model);
        }
      } catch (e) {
        result = new IdentityResult({ succeeded: false });
      }

      creatingAccount.value = false;
      displayCreateAccountDialog.value = false;

      if (result.succeeded) {
        root.$router.push({ name: "Login" });
      } else {
        errorMessage.value = "An error occurred while creating your account.";
      }
    }

    return {
      displayCreateAccountDialog,
      email,
      password,
      firstName,
      lastName,
      organization,
      creatingAccount,
      isFormValid,
      errorMessage,
      showPassword,
      emailRules,
      passwordRules,
      isOrganizationInvitation,
      registrationClicked,
      isEmailDisabled
    };
  }
});
